<!-- eslint-disable no-undef -->
<template>
  <div class="container">
    <div class="hero subhero">
      <div class="columns">
        <div class="column herotext">
          <h1>FAN<br />FACTORS</h1>
          <p class="hi">Fans are the “why” behind everything you do.</p>
          <p>
            Defining and segmenting key attributes of fan behavior collectively
            tells a story of how a fan engages with your organization. FanLab
            has analyzed tens of millions of data points from millions of
            professional and collegiate fan relationships to develop a ground
            breaking way to classify and segment fans, Fan Factors. Fan Factor
            segmentation is based on common behavioral and brand engagement
            attributes, divided into three segments: Disengaged, Casual, or
            Avid.
          </p>
          <img src="../assets/icons.png" alt="" />
          <br /><br /><br /><br />
        </div>
        <div class="column">
          <img class="fanimage" src="../assets/fanfactors.png" alt="" />
        </div>
      </div>
    </div>
    <div class="columns content">
      <div class="column">
        <h2>Disengaged</h2>
        <p>
          Typically considered the largest group of sports consumers, disengaged
          fans occasionally or rarely engage with sports or have an emotional
          connection. They spend their free time with other forms of
          entertainment and activities, unless it is a social environment where
          they can interact with friends and family without a sports
          affiliation. They might consider themselves a sports fan, but prefer
          social media as their sports outlet, where they get a free and
          unlimited experience without leaving their home.
        </p>
      </div>
      <div class="column">
        <h2>Casual</h2>
        <p>
          Casual fans have a genuine interest and will spend time and money on
          sports as a significant source of entertainment. Winning and losing
          can impact their mood and future engagement as a fan. They have a high
          potential to become an Avid fan and want to engage in tangible,
          interactive experiences that enrich their emotional connection
          throughout their fan journey.
        </p>
      </div>
      <div class="column">
        <h2>Avid</h2>
        <p>
          Separated by masses, Avid fans have a deep-rooted emotional commitment
          to the team. They are the holy grail of sports fans. Psychologically
          intertwined, their core identity and sense of community is built
          around their affiliation with the team. Regardless of win-loss record,
          they actively support the team through significant investment –
          Premium VIP benefits and experiences, merchandise, away game expenses,
          donations.
        </p>
      </div>
    </div>
    <div class="columns content">
      <div class="column">
        <p class="date" style="font-size: 22px;margin: 20px auto 20px auto;">
          With Fan Factors, your sales, marketing and business development teams
          can optimize success:
        </p>
        <ul>
          <li>
            Track fan segments and their movement from Disengaged, Casual, and
            Avid
          </li>
          <li>Customize digital marketing based on fan segmentation</li>
          <li>
            Access strategic insight by pushing Fan Factors data to your various
            downstream systems
          </li>
          <li>
            Build standout sponsorship proposals that drive conversion rates
          </li>
          <li>
            Know the precise current and lifetime value of a fan based on their
            classification and individual profile.
          </li>
        </ul>
        <router-link
          :to="{ name: 'Contact' }"
          class="homelink button is-primary"
        >
          Contact Us</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FanFactors",
  data() {
    return {
      msg: "foo",
    };
  },
  mounted() {
    document.title = "FanLab - Fan Factors";
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["setPath", "/fan-factors"]);
    _hsq.push(["trackPageView"]);
    document.body.classList.add("fanfactors");
  },
  destroyed() {
    document.body.classList.remove("fanfactors");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  display: list-item;
}
</style>
