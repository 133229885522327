<template>
  <div class="container">
    <div ref="capture" style="background:#1e1e1f;" class="capcap">
      <div class="hero subhero">
        <div class="columns">
          <div class="column herotext">
            <h1>ORGANIZATIONAL<br />READINESS<br />ASSESSMENT</h1>
            <p>
              SSB and Legend Labs bring decades of combined experience in
              supporting organizations that want to build advanced, impactful ,
              brand and customer engagement strategies driven by data. Their
              collective client bases include hundreds of professional leagues
              and franchises, collegiate athletic departments and conferences,
              universities and superstar athletes. This deep experience has
              shaped a robust understanding of the core attributes successful
              organizations share as they compete to engage fans and drive
              revenue.
            </p>

            <img src="../assets/icons.png" alt="" />
            <br /><br />
          </div>

          <div class="column ready">
            <img src="../assets/ready.png" alt="" />
          </div>
        </div>
      </div>
      <a class="scroll"></a>
      <div class="intro content" v-if="viewResults !== true">
        <p class="hi">
          The Organizational Readiness Assessment seeks to understand how
          systems are being used, the extent to which actionable intelligence is
          being extracted, and how well poised your organization is to build and
          protect its brand. Take the complimentary Organizational Readiness
          Assessment now.
        </p>
        <h3 class="blue">Guidance:</h3>
        <ul>
          <li>Answer individually or collaborate with others</li>
          <li>
            Compare your answers with colleagues to assess internal alignment
          </li>
          <li>
            Leverage the recommendations provided for your strategic planning
          </li>
          <li>
            <router-link :to="{ name: 'Contact' }">Contact us</router-link> for
            a complimentary analysis of the results
          </li>
        </ul>
      </div>
      <div class="content">
        <div v-if="viewResults !== true" style="display: flex;flex-wrap: wrap;">
          <div v-for="item in questions" :key="item.q" class="qs">
            <h1 v-html="item.q"></h1>
            <div class="control inlines">
              <input type="radio" :id="item.q" v-model="item.a" :value="true" />
              <label :for="item.q" class="yes">
                Yes
              </label>
              <input
                type="radio"
                :id="item.as"
                v-model="item.a"
                :value="false"
              />
              <label :for="item.as" class="no">
                No
              </label>
            </div>
          </div>
          <!-- <div class="buttonWrap">
          <button class="button is-primary" @click="viewRes()">
            View Results
          </button>
        </div> -->
        </div>

        <div class="formGroup" v-if="submitted !== true">
          <div class="columns">
            <div class="column">
              <div class="field">
                <input
                  class="input"
                  type="text"
                  v-model="name"
                  required
                  placeholder="Name *"
                />
              </div>
            </div>
            <div class="column">
              <div class="field">
                <input
                  class="input"
                  type="text"
                  v-model="email"
                  placeholder="Email *"
                  required
                />
              </div>
            </div>
            <div class="column">
              <div class="field">
                <input
                  class="input"
                  type="text"
                  v-model="org"
                  required
                  placeholder="Organization *"
                />
              </div>
            </div>
          </div>

          <div class="buttonWrap" v-if="submitted !== true">
            <div class="msg">{{ msg }}</div>
            <button class="button is-primary" @click="submit()">
              View Results
            </button>
          </div>
        </div>

        <div class="title sharetitle" v-if="submitted == true">
          <h2><br />Results</h2>
          <div class="button is-primary clickshare" @click="shareForm()">
            Share
          </div>
        </div>

        <div
          class="results"
          style="background-color: #1e1e1f;"
          v-if="submitted == true"
        >
          <div class="brand">
            <div class="resWrap">
              <div>
                <h1>Brand</h1>
                <div class="cent">
                  {{ Math.ceil(bcent) }}<span class="ff">%</span>
                </div>
              </div>
              <div class="resAns">
                <div>
                  <h2 class="asses" style="margin-top: 0;">Assessment</h2>
                </div>
                <div v-for="item in questions" :key="item.as">
                  <div v-if="item.cat === 'brand'">
                    <div v-if="item.a === true" class="ansItem">
                      <div class="ansq" v-html="item.q"></div>
                      <div class="y">Yes</div>
                      <div v-html="item.as"></div>
                    </div>
                    <div v-if="item.a === false" class="ansItem">
                      <div class="ansq" v-html="item.q"></div>
                      <div class="n">No</div>
                      <div v-html="item.an"></div>
                      <div v-html="item.ank"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="brand">
            <div class="resWrap">
              <div>
                <h1>Systems Optimization</h1>
                <div class="cent">
                  {{ Math.ceil(bcent) }}<span class="ff">%</span>
                </div>
              </div>
              <div class="resAns">
                <div>
                  <h2 class="asses">Assessment</h2>
                </div>
                <div v-for="item in questions" :key="item.as">
                  <div v-if="item.cat === 'systems'">
                    <div v-if="item.a === true">
                      <div class="ansq" v-html="item.q"></div>
                      <div class="y">Yes</div>
                      <div v-html="item.as"></div>
                    </div>
                    <div v-if="item.a === false">
                      <div class="ansq" v-html="item.q"></div>
                      <div class="n">No</div>
                      <div v-html="item.an"></div>
                      <div v-html="item.ank"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="brand">
            <div class="resWrap">
              <div>
                <h1>Business Intelligence</h1>
                <div class="cent">
                  {{ Math.ceil(bucent) }}<span class="ff">%</span>
                </div>
              </div>

              <div class="resAns">
                <div>
                  <h2 class="asses">Assessment</h2>
                </div>
                <div v-for="item in questions" :key="item.as">
                  <div v-if="item.cat === 'business'">
                    <div v-if="item.a === true" class="ansItem">
                      <div class="ansq" v-html="item.q"></div>
                      <div class="y">Yes</div>
                      <div v-html="item.as"></div>
                    </div>
                    <div v-if="item.a === false" class="ansItem">
                      <div class="ansq" v-html="item.q"></div>
                      <div class="n">No</div>
                      <div v-html="item.an"></div>
                      <div v-html="item.ank"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="share" v-if="overlay == true" @click="overlay = false">
      <img :src="output" />
      <div class="button is-primary" @click="saveForm(output)">Download</div>
    </div>
  </div>
</template>

<script>
const firebase = require("firebase");
require("firebase/functions");

const firebaseConfig = {
  apiKey: "AIzaSyBGYghZDmBeT424JdsPXVoOSRIaXpUD5ew",
  authDomain: "fanlab.firebaseapp.com",
  databaseURL: "https://fanlab.firebaseio.com",
  projectId: "fanlab",
  storageBucket: "fanlab.appspot.com",
  messagingSenderId: "1067460664845",
  appId: "1:1067460664845:web:f6875ac7dbc135d9fbff44",
};
!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : "";

// Initialize Cloud Functions through Firebase
var functions = firebase.functions();
let sendMail = functions.httpsCallable("sendMails");
var _hsq = (window._hsq = window._hsq || []);

export default {
  name: "Assessment",
  components: {},
  data() {
    return {
      msg: "",
      overlay: false,
      output: null,
      valid: true,
      bcent: 0,
      scent: 0,
      bucent: 0,
      overall: [],
      brand: [],
      systems: [],
      business: [],
      showRes: false,
      formSent: "",
      submitted: false,
      viewResults: false,
      name: "",
      email: "",
      org: "",
      questions: [
        {
          q:
            "<span>Q1</span> Can you easily articulate your organization's purpose and what sets it apart from peers/competitors?",
          a: null,
          as:
            "Clear articulation of your organization’s purpose, competitive position and relevant differentiation define your brand foundation and allow for effective engagement with key stakeholders. With this established, you can focus on sharing your brand story, elevating your brand image and building loyalty through new and dynamic messages, mediums and channels.",
          an:
            "When people hear the word “brand” they often think of an organization’s logo and leave so much on the table. Lasting brands define an organization’s image through every touch point. Built on a comprehensive understanding of the organization’s relevant differentiation, they beg the question, “what do we do that no one else does and why should people care?” A well-crafted positioning statement should articulate ownable and aspirational traits and elicit an emotional response while enhancing understanding and ownership of the brand. The energy, tone and messages conveyed will further shape and refine the brand identity and inspire stories that attract and engage key constituents.",
          ank:
            "<ul> <li>Make the positioning process highly inclusive. At the end of the exercise you want the right people to feel a sense of ownership so that they become ambassadors of the new story.</li> <li>Use opportunities like major crises, leadership changes and significant successes to reevaluate your positioning. Do you need to begin to tell a new story? </li> </ul>",
          cat: "brand",
        },
        {
          q:
            "<span>Q2</span> Does every part of your organization communicate the brand story consistently and effectively?",
          a: null,
          as:
            "Internal audiences are often overlooked as a powerful and effective tool for brand building. Further developing the internal brand culture will help align the organization while building ownership and engagement among employees as external storytellers and brand ambassadors.",
          an:
            "Develop an internal brand culture through dedicated programs that empower employees to become brand advocates. Serving as brand champions, they will be able to consistently and effectively communicate the brand story and unlock tremendous opportunity to expand the brand at-scale while directly impacting job satisfaction, retention and recruitment.",
          ank:
            "<ul> <li>This can begin at the brand positioning phase by actively involving key internal audiences in the articulation of your brand’s differentiation and personality.</li> <li>Because not everyone is a natural storyteller, this makes training critical to success. Brand key messages for easy recall, collect stories and data for a rich blend of rational and emotional communications and celebrate effective delivery of the brand story as reference for others.</li> <li>In decentralized organizations find ways to collect best practices and drive alignment in the story through the creation of “Centers of Excellence” in communication and marketing. Executive alignment will dictate the adoption of your storytelling culture.</li> </ul>",
          cat: "brand",
        },
        {
          q:
            "<span>Q3</span> Does your branding (logo, colors, etc.) reflect how you want to be perceived?",
          a: null,
          as:
            "You have a strong visual identity (logo, colors, typeface) that clearly identifies and positions you in the market including instant recognition and sustained recall among key targets. While adding additional depth to your brand identity may take some time, there is always opportunity to expand overall reach and engage new audiences through professional alliances, community engagement, new experiences and ongoing collaborations.",
          an:
            "Your brand identity should holistically represent everything your organization stands for in alignment with your brand positioning statement. The symbolism conveyed through your brand should showcase your personality along with the substance behind the brand to include the organization’s purpose, people and history. Today’s brands must also be responsive, able to navigate the competitive landscape and harness digital media through a dynamic user experience with motion graphics and audio expressions.",
          ank:
            "<ul> <li>The bar for a complete brand identity refresh should be high. In many cases it’s the elements around the brand such as content and fan segmentation that can more meaningfully impact the business.</li> <li>Emphasize longevity in the creative process. Updating everything from stationary to signage is a meticulous process that shouldn’t have to be revisited for many years.</li> </ul>",
          cat: "brand",
        },
        {
          q:
            "<span>Q4</span> Are your communications & marketing teams adequately resourced (personnel, skills, budgets)?",
          a: null,
          as:
            "Having a robust and capable team is a huge asset and will certainly provide significant competitive advantage. At this stage, you should identify additional opportunities to bolster communications and marketing performance in the digital arena through advanced digital analytics, content creation, search engine optimization and paid media.",
          an:
            "The digital revolution has dramatically impacted marketing and communications and the skills and technology needed to effectively compete in the online world. Instant access to information has altered consumer behavior and consumption habits while shifting entire industries to a new way of conducting business. The evolution of the digital age has been further accelerated by a global pandemic putting additional strain on resources and forcing us to rethink commerce. At the same time, access to more data than ever before presents tremendous opportunity to succeed with compelling content, personalized engagement and continuous optimization through digital analytics. An organizational assessment will map your current capabilities while identifying vulnerabilities as compared to competitors and industry trends. This is a critical step toward building a more powerful marketing and communications function.",
          ank:
            "<ul> <li>Build a structural blueprint with insight on capabilities, headcount, technology and budgets.</li> <li>Evaluate in-house and agency resources for a clear picture on self-sufficiency.</li> </ul>",
          cat: "brand",
        },
        {
          q:
            "<span>Q5</span> Do you use data to segment key audiences and develop custom engagement strategies?",
          a: null,
          as:
            "Your organization’s efforts in leveraging data for personalized engagement likely puts you at a major competitive advantage. Key audiences may include employees, the local community, government officials, the media and other influencers, as well as fans. The more advanced your key audience and fan segmentation is, the more targeted, personalized and impactful your engagement will be. <a href='https://fanlab.us/fan-factors' target='_blank'>Explore Fan Factors’ groundbreaking new way to segment fans.</a>",
          an:
            "Successful marketing and communications efforts often begin with a clear understanding of the target audience in order to achieve data-driven personalized engagement. This can extend from indexing the number of social media followers that brand influencers have to inform strategic partnerships, to quantifying site traffic to influential media outlets to prioritize story pitching or paid media placements. Perhaps the most important use of data in audience segmentation is building a profile of your fans. SSB has analyzed tens of millions of data points from nearly 10 million professional and collegiate fan relationships to develop a groundbreaking new way to segment fans: <a href='https://fanlab.us/fan-factors' target='_blank'>Fan Factors’.</a>",
          ank:
            "<ul> <li>Based on common behavioral and brand engagement attributes, fans are divided into three segments: Disengaged, Casual, or Avid.</li> <li>Using Fan Factor’s intelligence, organizations can tailor personalized engagement strategies to move fans toward Avid and away from Disengaged.</li> <li>Integrating supplemental data sets allows for Fan Factor types to be further segmented into six subsets, creating opportunities for exceptionally individualized engagement strategies.</li> </ul>",
          cat: "brand",
        },
        {
          q:
            "<span>Q6</span> Do you use digital listening technology to track online conversations related to your organization, industry and competitors?",
          a: null,
          as:
            "Digital listening technology is a powerful new weapon in the communications and marketing arsenal. Allowing you to track conversations related to your organization, think of this as “brand radar” that provides actionable real-time intelligence on engagement opportunities and threats to your reputation. By expanding tracking to competitor brands and industry trends, you can maximize this resource to more effectively navigate the competitive landscape while proactively reducing vulnerabilities.",
          an:
            "Digital listening technology collects every publicly available mention of keywords or phrases that you deem to be relevant to your brand. This includes mentions of your organization, key leadership, athletes, coaches and more. The most sophisticated and actionable configurations collect conversations about competitors for strategic positioning and benchmark comparisons. You can also track industry topics and trends as a powerful tool for advanced planning, reputation management and crisis readiness.",
          ank:
            "<ul> <li>Evaluate leading providers like Brandwatch, Meltwater and Sprinklr.</li> <li>Identify key topics that if tracked effectively, could help your team shape its engagement strategy and subsequently measure success.</li> <li>Evaluate the in-house analytical talent you have to effectively use the technology and disseminate actionable insights to leaders on a regular basis.</li> </ul>",
          cat: "brand",
        },
        {
          q:
            "<span>Q7</span> Do you have a crisis communication plans to protect against operational and reputational vulnerabilities?",
          a: null,
          as:
            "Having a comprehensive crisis communications plan and support materials prepared in advance will minimize vulnerabilities and threats and serve as an invaluable tool to protect your brand image while mitigating long-term reputational damage. With a plan in place, you might want to integrate digital listening into your vulnerability tracking to improve issue detection and response. You can also pressure test your plans with crisis simulation. For more best practices see <a href='https://brandunderfire.com' target='_blank'>Brand Under Fire: A New Playbook for Crisis Management in the Digital Age.</a>",
          an:
            "It’s not a question of “if” but rather “when” your organization will experience a crisis. Given the global pandemic, this has become an ever-increasing reality for countless organizations. Further complicating the challenge of reputation management, we live in the digital age with nanosecond news cycles, news outlets increasingly focused on speed over accuracy and smartphones turning everyone into journalists. Crisis and issues preparedness is critical for your organization to successfully protect its brand image.",
          ank:
            "<ul> <li>Start your crisis preparations with the five key principles for effective crisis response: speed, agility, transparency, authenticity and creativity. Act fast and be responsive to new developments while recognizing that it’s not a question of if you disclose, but how. Deliver messages from people not entities and provide information with substance that people will engage with. </li> <li>Interview leadership, benchmark peers and index industry trends to develop a profile of your brand’s vulnerabilities. Prioritize these by likelihood of occurrence and potential impact and use this list as the basis for creating preparedness materials.</li> <li>Pressure test your organizational preparedness with full or tabletop simulations that include executive, legal, digital and communications teams. It’s important to have cultural alignment in how you would respond to a crisis. Indecision or disagreement in the moment can be costly.</li> <li>Build a plan to monitor vulnerabilities. For example, digital listening can be a powerful issue detection and early warning tool.</li> <li>For more best practices see <a href='https://brandunderfire.com' target='_blank'>Brand Under Fire: A New Playbook for Crisis Management in the Digital Age.</a></li> </ul>",
          cat: "brand",
        },
        {
          q:
            "<span>Q8</span> Are you seeing measurable business results from your use of data as an organization?",
          a: null,
          as:
            "Leveraging clean, organized, scalable and secure business data is key in showing measurable results. Closely tracking the entire journey of a fan, including where they originated, who they talked to, and how they engaged, will contribute more insights and provide a clearer understanding on where they generated revenue for your business. You will also be able to understand the current health of your business through near-real time reporting and tracking of key performance indicator (KPI) metrics.",
          an:
            "Understanding how data can help you will provide a significant advantage and you don’t have to be a data analyst to understand and benefit from these resources. Answering strategic business questions in minutes, taking action and measuring your impact is an important part of business operations. While all organizations generally agree on the importance of data as a valuable asset, the reality is that everyone begins at a different starting point when forming a data strategy. While some companies have teams dedicated to data analysis and management, most organizations have a measured approach to incorporating data and manage this through a limited number of team members with varied expertise. Starting with a foundational approach and working in a deliberate manner is key to success. Near-real time reporting and tracking of key performance indicator (KPI) metrics will allow you to understand the current health of your business and provide a complete understanding of your fan base and their interactions with the organization.",
          ank:
            "<ul> <li>Identify an internal champion: Determine the individual(s) that have an interest in data and start the conversation on how this might grow organically within your organization.</li> <li>If you have a small team you are likely focused on balancing strategy, staffing, software and support because all four need to be in balance to operate at maximum efficiency. Segment your efforts into specialized areas based on the skills and interests each member of your team possesses such as email marketing, digital marketing, marketing strategy and revenue generation.</li> <li><a href='https://ssbinfo.com/case_study/monumental-builds-revenue-with-fan-insights-platform/' target='_blank'>View Use Case</a></li> <li><a href='https://ssbinfo.com/products-services/central-intelligence/' target='_blank'>View Product</a></li> </ul>",
          cat: "systems",
        },
        {
          q:
            "<span>Q9</span> Are the systems you use to track ticketing, donations, purchases, email engagement, etc. all integrated to give you a single view of your customer?",
          a: null,
          as:
            "The key is to create an integrated solution that aligns objectives across departments while encouraging for cross-team collaboration – as opposed to processing/analyzing information in silos. Educating all team members should be a focus so that everyone understands the capabilities available at their fingertips. Setting organizational goals that align with departmental goals and celebrating successes will help bring data to the forefront and encourage participation throughout the organization.",
          an:
            "Data and actionable business intelligence about your customers is often found in disparate systems across the organization. You have hundreds of data points and unique attributes about individuals who engage with your brand in ticketing, CRM, merchandise, concession, donation systems and more. Connecting these sources to deliver one single view of the customer (Golden Record) is difficult and time-consuming if you don’t have the proper tools. Having a single source of truth for all customer touchpoints will create incremental revenue opportunities. If you are considering utilizing a data warehouse, always start with the sources that make your efforts more accurate and efficient while building this up in phases.",
          ank:
            "<ul> <li>Consult with different departments to assess what they have, what they need and where their data is stored. Understand department goals and how they are using data to reach them (their desired outcomes).  While this requires a lot of effort, it is highly recommended to uncover the organization's top priorities and create alignment among departments.</li> <li>Take a phased approach that meets the needs of your organization. Getting multiple quick wins out of the gate will help make what appears overwhelming at the beginning, more manageable.</li><li><a href='https://ssbinfo.com/case_study/td-garden-boston-bruins-maximize-data-sources/' target='_blank'>View Use Case</a></li> <li><a href='https://ssbinfo.com/products-services/central-intelligence/' target='_blank'>View Product</a></li> </ul>",
          cat: "systems",
        },
        {
          q:
            "<span>Q10</span> Do other parts of your organization have important systems that do not integrate with yours? Examples: CRM to Datawarehouse, Athletics Department systems with University systems",
          a: null,
          as:
            "The ideal approach to data management and business intelligence is a cohesive system that is both efficient and effective. Efficiently measuring key objectives that inform short and long-term strategic planning while eliminating redundant tasks for staff. Effectively increasing ROI through successful marketing, sales and service initiatives that meet the needs of the customer while uncovering valuable business insights that would have been impossible to gather otherwise.",
          an:
            "Accessible and well-integrated data that provides a single version of the truth (Golden Record) will allow you to make smarter and more responsive business decisions in real-time that maximize valuable resources and minimize waste. Whether your goal is to increase ticket sales or engage donors, understanding your current customers is the first step...and to understand them, you need to understand the data. Start by making a list of all possible sources of data which can be accomplished by identifying systems, or thinking about the questions you want answer about a fan. For example, when was their last ticket purchase? While many people may stop at their Primary Ticketing Vendor, including secondary market purchases from your preferred Broker(s) may provide a more complete picture.",
          ank:
            "<ul> <li>Once you have a comprehensive list of systems, create a data flow diagram (flow chart) to visually map the flow of data. Include all of your technology vendors and data sources, as well as how they interact with each other. Be prepared that your data flow diagram may look a lot like Spaghetti Junction, especially if your organization relies on information from different systems. To begin to make sense out of everything, prioritize data sources most important to your customer profile.</li><li><a href='https://ssbinfo.com/wp-content/uploads/2019/02/South_Carolina_Case_Study.pdf' target='_blank'>View Use Case</a></li> <li><a href='https://ssbinfo.com/products-services/central-intelligence/' target='_blank'>View Product</a></li> </ul>",
          cat: "systems",
        },
        {
          q:
            "<span>Q11</span> Do the tools you use allow you to easily define, track progress, and automate reporting on business objectives?",
          a: null,
          as:
            "Continuously review where you can get additional quick wins and evolve in the areas the generate the greatest impact. Once you show ROI on your data investments and accelerate the decision-making process, the more likely you are to continue to get buy-in throughout the organization. Delivering actionable business intelligence in a timely manner will not only allow everyone to achieve their goals and objectives, it will further demonstrate the inherent power of data tracking and analysis.",
          an:
            "For data to have value it needs to be put into action. Accurate, efficient and frequently updated reporting allows you to visually comprehend the information and convert it into actionable insights. Rather than waiting for static reports only to spend hours in Excel interpreting data, your team will be able to quickly understand complex data systems and customer relationships in visual form requiring no technical expertise. This efficiency reduces countless hours of administrative time per week, freeing up employees to spend more time on critical strategic initiatives as educated team members making informed decisions.",
          ank:
            "<ul> <li>Put together an inventory of the reports your team currently creates manually and start asking questions about how long they take to generate. This will stimulate conversation about what can be automated – the answers will likely astound you.</li> <li><a href='https://ssbinfo.com/products-services/central-intelligence/' target='_blank'>View Product</a></li> </ul>",
          cat: "business",
        },
        {
          q:
            "<span>Q12</span> Are you leveraging predictive analytics or modeling to surface actionable insights to meet business goals?",
          a: null,
          as:
            "Consider adding additional data sources and appends to enrich the dataset and make predictive analytics and modeling more robust. Appends deepen customer understanding by merging your customer data with third party information that ranges from demographics to interests and donor affinity. SSB’s data appends allows you to drive incremental revenue through data models based on look-a-like audiences that reveal behavioral and demographic patterns/tendencies.",
          an:
            "Utilizing lead scoring models, such as SSB Analytics, will provide an extra edge in sales and service that drives new business and maximizes results from existing purchasers. Historically, teams look at look at prior years' data to prioritize sales efforts which is time consuming and inefficient. With automated data analytics as the new gold standard, you can better qualify sales leads by predicting not only which fans are more likely to buy tickets, but also what kind of tickets they are likely to buy. With limited resources, maximizing the effectiveness of your marketing efforts with automated data analytics is essential.",
          ank:
            "<ul> <li>Integrate propensity scores into lead scoring models and enhance your CRM by identifying which leads are the most appropriate to target immediately.</li><li><a href='https://ssbinfo.com/case_study/uw-scores-quick-wins-with-ssb-propensity-scores/' target='_blank'>View Use Case</a></li> <li><a href='https://ssbinfo.com/products-services/analytics/' target='_blank'>View Product</a></li> </ul>",
          cat: "business",
        },
        {
          q:
            "<span>Q13</span> Are you able to easily leverage your data to demonstrate value or ROI to your corporate sponsors?",
          a: null,
          as:
            "An in-depth 360-degree understanding of the customer is essential to growing and retaining sponsor dollars. For example, a statewide heat map can help drive partnership opportunities by giving potential sponsors an up-to-date and accurate idea of where the most fans are concentrated near key points of sale. In addition to uncovering in state business and national opportunities, customizable data can help sports organizations become better storytellers on behalf of their sponsors.",
          an:
            "Unlike traditional methods that generate static and often dated information, a robust data management program offers user-friendly reporting so you can easily pull real-time data and deliver dynamic information to sponsors in minutes. A fully automated approach requires a fraction of the time needed to create spreadsheets and presentations from scratch. For example, KPIs combined with traditional impressions metrics from signage, TV and radio will create a robust sponsorship program fueled by proven ROI. And Sponsorship Central changes the game by offering a world-class solution that integrates every step of a partnership. From the first sales contact to fulfilling the last activation of the season, it delivers day-to-day measurability, accountability and efficiency.",
          ank:
            "<ul> <li>According to A.J. Maestas, CEO and Founder of Navigate, new business models and other changes are on the horizon. About 95% of collegiate sports organizations outsource sponsorship sales to a third party, but without revenue certainty they may exit those agreements and move them in-house. Revenue sharing models will also be proposed where business partners share risk/reward with universities and properties, further emphasizing the importance of demonstratable ROI.</li> <li>Data can also be used to support sales pitches, measure success and demonstrate ROI. For example, Monumental Sports had the data it needed for an upsell pitch with Bud Light where they connected game beer sales to ROI in near-real-time.</li> <li>Make strategic improvements by leveraging league level benchmarking, ongoing analysis and best practice sharing.</li><li><a href='https://ssbinfo.com/overcoming-sponsorship-challenges-with-data-and-technology/' target='_blank'>View Use Case</a></li> <li><a href='https://ssbinfo.com/products-services/sponsorship-central/' target='_blank'>View Product</a></li> </ul>",
          cat: "business",
        },
        {
          q:
            "<span>Q14</span> Is your organization actively reevaluating how and where it can most effectively meet business imperatives?",
          a: null,
          as:
            "If FanLab can provide case studies or best practices to accelerate or support in your reevaluation efforts, please dont hesitate to reach out at the Contact page.",
          an:
            "Refocused investment in brand activation, business intellignece and/or systems optimization can begin with an internal champion. References to industry or peer case studies and best practices can be useful references points as you build a case. FanLab is here to help.",
          ank:
            "<ul> <li>Share your results from this Organizational Zreadiness Assessment with other key internal stakeholders. </li> <li>Encourage them to take the assessment independently and compare results to gauge inetnall alignment.</li> <li>If FanLab can provide case studies or best practices to accelerate or support in your reevaluation efforts, please dont hesitate to reach out at the Contact page.</li> </ul>",
          cat: "screener",
        },
      ],
    };
  },
  methods: {
    sanitizeHTML(str) {
      var temp = document.createElement("div");
      temp.textContent = str;
      return temp.innerHTML;
    },

    submit() {
      let final = [];
      let brand = [];
      let business = [];
      let systems = [];
      let email = this.email;
      let name = this.name;
      let org = this.org;
      let pat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      document.querySelector(".scroll").scrollIntoView({
        behavior: "smooth",
      });
      this.questions.forEach((item) => {
        final.push(item.a);
        if (item.cat == "brand") {
          brand.push(item.a);
          if (item.a == true) {
            this.bcent += 14.2;
            this.brand.push(item.a);
          }
        }
        if (item.cat == "systems") {
          systems.push(item.a);
          if (item.a == true) {
            this.scent += 33.3;
            this.systems.push(item.a);
          }
        }
        if (item.cat == "business") {
          business.push(item.a);
          if (item.a == true) {
            this.bucent += 33.3;
            this.business.push(item.a);
          }
        }
      });
      this.valid = true;
      this.questions.forEach((item) => {
        if (item.a === null) {
          this.valid = false;
        }
      });
      if (this.valid == false) {
        this.msg = "Please answer all questions.";
      } else {
        if (email === "" || name === "" || org === "") {
          this.msg = "Please fill out all form fields.";
        } else if (!email.match(pat)) {
          this.msg = "Please use a valid email address.";
          // this.sendForm(final);
          // this.submitted = true;
          // this.showRes = true;
        } else {
          this.viewResults = true;
          this.valid = true;
          this.msg = "";
          this.sendForm(final);
          this.submitted = true;
          this.showRes = true;
        }
      }
    },
    sendTracking() {
      // eslint-disable-next-line
      _hsq.push([
        "trackEvent",
        {
          id: "Assessment Form Subbmitted",
          value: true,
        },
      ]);
      let res = this.questions;
      res.forEach((r, index) => {
        // eslint-disable-next-line
        _hsq.push([
          "trackEvent",
          {
            id: "Question_" + (index + 1),
            value: r.a,
          },
        ]);
      });
    },
    sendForm(final) {
      this.sendTracking();
      sendMail({
        body: {
          name: this.sanitizeHTML(this.name),
          email: this.sanitizeHTML(this.email),
          org: this.sanitizeHTML(this.org),
          results: this.sanitizeHTML(JSON.stringify(final)),
        },
      })
        .then(() => {
          this.formSent = "Sent!";
        })
        .catch((error) => {
          this.formSent = "Failed!";
          console.log(error);
        });
    },
    shareForm() {
      this.overlay = true;
      const el = this.$refs.capture;
      const options = {
        logging: false,
        type: "dataURL",
      };
      (async () => {
        this.output = await this.$html2canvas(el, options);
      })();
    },
    saveForm(url) {
      // eslint-disable-next-line
      _hsq.push([
        "trackEvent",
        {
          id: "User Downloaded Results",
          value: true,
        },
      ]);
      var a = document.createElement("a");
      a.href = url;
      a.download = "FanLabs-Results.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  mounted() {
    document.title = "FanLab - Fan Engagment Assessment";
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["setPath", "/assessment"]);
    _hsq.push(["trackPageView"]);
    document.body.classList.add("assessment");
  },
  destroyed() {
    document.body.classList.remove("assessment");
  },
};
</script>

<style>
h2.asses {
  margin-top: 0;
  font-size: 22px;
  color: #34ace2 !important;
}

.resWrap h1 {
  max-width: 200px;
  margin-top: 0;
  font-size: 32px;
}
.cent {
  background: #34ace2;
  width: 190px;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 62px;
  border-radius: 5px;
  margin-bottom: 60px;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  font-weight: 600;
}
.inlines label {
  display: block;
  position: relative;
  padding: 10px 20px;
  background: transparent;

  margin: 10px;
  border: 2px solid #eee;
  border-bottom-color: #fff !important;
  color: #fff;
}
.control.inlines input {
  z-index: -9999;
  position: absolute;
  left: 31%;
}
input[type="radio"]:checked + label.no {
  background: #ee3825 !important;
  color: #fff;
}
input[type="radio"]:checked + label.yes {
  background: #00aeef !important;
  color: #fff;
}

.inlines label {
  transition: 0.2s all ease;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.inlines label:hover {
  transform: translateY(-5px);
}
/* .inlines label.yes:hover {
  border-bottom: 2px solid #00aeef !important;
}
.inlines label.no:hover {
  border-bottom: 2px solid #ee3825 !important;
} */
canvas {
  height: 100%;
  height: 400px;
  width: 500px;
}
.resWrap {
  display: flex;
  text-align: left;
}
.buttonWrap {
  text-align: center;
}
.msg {
  color: #f64d3b;
}
.share {
  position: fixed;
  background: rgb(10 10 10 / 84%);
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  padding: 31px;
  border: 1px solid;
  border-radius: 3px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.share img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
}
.sharetitle {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
}
.sharetitle .button {
  padding: 8px 20px;
  height: auto !important;
  font-size: 22px;
  margin-top: 0 !important;
  margin-top: -10px !important;
  margin-bottom: 30px;
}
.qs span {
  /* background: #00aeef; */
  color: #34ace2;
  padding: 20px;
  font-size: 20px;
  padding: 3px;
  border-radius: 3px;
  font-family: "EucBld";
  margin-bottom: 0;
  float: none;
  margin-right: 0px;
  width: 69px;
  height: 56px;
  display: block;
  font-size: 24px;
  float: left;
}
.control.inlines {
  display: flex;
  align-items: center;
  justify-content: center;
}
.y {
  font-family: "EucBld";
  color: #36bd43;
  font-size: 22px;
}
.n {
  font-family: "EucBld";
  color: #36bd43;
  font-size: 20px;
}
.n {
  font-family: "EucBld";
  color: #ee3825;
  font-size: 20px;
}
.ansq span {
  color: #7ec5e4;
}
.ff {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  font-size: 40px;
  font-weight: 600;
}
</style>
