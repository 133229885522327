<template>
  <div class="hello">
    <script
      type="application/javascript"
      async
      src="//js.hsforms.net/forms/v2.js"
    ></script>
    <div class="hero subhero">
      <div class="columns">
        <div class="column herotext">
          <h1>The Modern Art Behind Building Successful Brands in Sports</h1>
          <p class="hi">
            An exploration of sports fan engagement through the lens of
            communications and marketing in building, projecting, and defending
            brands
          </p>
          <p class="date">THURSDAY, JANUARY 7 AT 1 PM CST</p>
          <p>
            Veteran sports executive, Steve Patterson, and brand & digital
            strategist, James Wakefield, dissect organizational attributes that
            drive success in sports fan engagement. In this webinar, we’ll
            explore actionable strategies, best practices and imperatives for
            the modern sports executive focused on the "Art" in FanLab’s “Art +
            Science” engagement equation.
          </p>
          <br />
          <p class="date">
            In this webinar, we will discuss how organizations can:
          </p>
          <div class="content">
            <ul>
              <li>
                Build a storytelling culture for your brand positioning
              </li>
              <li>
                Be more competitive in a crowded communications landscape
              </li>
              <li>
                Deliver segmented messages to drive monetizable engagement
              </li>
              <li>
                Leverage cutting edge technology to understand and act on
                conversations about your brand
              </li>
              <li>
                Protect brand equity that you build with advanced reputation
                management strategies
              </li>
            </ul>
          </div>
        </div>

        <div class="column ready webinar">
          <img src="../assets/webinar2.png" alt="" />
        </div>
      </div>
    </div>
    <div id="form1"></div>
  </div>
</template>

<script>
export default {
  name: "Webinar",
  data() {
    return {
      msg: "bar",
    };
  },
  mounted() {
    document.title = "FanLab - Webinars";
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["setPath", "/webinar"]);
    _hsq.push(["trackPageView"]);
    document.body.classList.remove("Webinar");
    let d = document.getElementsByClassName("hbspt-form")[0];
    if (d) {
      d.remove();
    }
    document.body.classList.add("Webinar");
    setTimeout(() => {
      // eslint-disable-next-line
      hbspt.forms.create({
        portalId: "6994075",
        formId: "1adcc430-d74d-429b-bae2-d577d7514e1d",
        target: "#form1",
      });
    }, 1000);
  },
  destroyed() {
    document.body.classList.remove("Webinar");
    let d = document.getElementsByClassName("hbspt-form")[0];
    let d1 = document.getElementsByClassName("hbspt-form")[1];
    if (d || d1) {
      d.remove();
      d1.remove();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
p.date {
  color: #01c2f7;
  font-family: "EucBld";
  font-size: 17px;
  margin-bottom: 15px;
}
p.hi {
  font-size: 21px;
  margin-top: -16px;
  margin-bottom: 40px;
}
</style>
