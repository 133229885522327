<!-- eslint-disable no-undef -->
<template>
  <div>
    <div class="homebg"></div>
    <div class="homeContent">
      <img class="logo1" src="../assets/fanw.png" alt="" />
      <div class="lead">
        <h1>The Art and Science of Effective Sports Fan Engagement</h1>
      </div>
      <div class="brought">
        <h2>Brought to you by</h2>
      </div>
      <img class="logo3" src="../assets/logo2.png" alt="" />

      <p class="test">
        The sports fan remains central to driving organizational success and
        revenue. Fans differ in enthusiasm, engagement and devotion. This leads
        to variations in spending. Now, more than ever, organizations must
        efficiently and cost-effectively engage with fans in a meaningful and
        personal way that meets their individual needs. This drives reciprocal
        engagement and leads to revenue growth. FanLab blends the art of
        communication with the science of data and creativity to classify,
        segment and connect with the modern sports fan.
      </p>
      <div class="container">
        <div class="columns">
          <div class="column">
            <img class="homeread" src="../assets/ready.png" alt="" />
          </div>
          <div class="column" style="text-align: left;">
            <p class="date homedate">
              Is your organization ready to take fan engagement to the next
              level?
            </p>
            <p>
              Complete a complimentary Organizational Readiness Assessment that
              analyzes your brand activation, communication, business
              intelligence and systems optimization readiness. The assessment
              delivers actionable feedback, case studies, and recommendations
              based on your individual profile.
            </p>

            <router-link
              :to="{ name: 'Assessment' }"
              class="homelink button is-primary"
            >
              Free Readiness Assessment</router-link
            >
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <img class="homeff" src="../assets/ff.png" alt="" />
          </div>
          <div class="column" style="text-align: left;">
            <p class="date homedate">
              How many of your fans are Avid, Casual or Disengaged?
            </p>
            <p>
              Fan Factors, SSB’s groundbreaking new way to classify and segment
              fans, utilizes tens of millions of data points from millions of
              professional and collegiate fan relationships. Explore how
              advanced segmentation can shape actionable fan engagement
              strategies that drive enthusiasm, devotion and, ultimately, their
              propensity to spend.
            </p>
            <router-link
              :to="{ name: 'FanFactors' }"
              class="homelink button is-primary"
            >
              Fan Factors</router-link
            >
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <img class="homeff" src="../assets/ssb.png" alt="" />
          </div>
          <div class="column" style="text-align: left;">
            <p class="date homedate">
              About SSB
            </p>
            <p>
              SSB maximizes revenue and fundraising efforts for the higher
              education, collegiate athletics, professional sports and nonprofit
              industries through data management, data analytics, gift matching
              and CRM products and services. SSB provides ongoing access to
              relevant, real-time data and business intelligence that can be
              easily implemented toward driving fundraising and revenue goals.
              SSB serves over 1,500+ professional sports organizations,
              collegiate athletic departments, higher education institutions and
              non-profits. SSB was named the 2017 Microsoft U.S. Education
              Partner of the Year and is a contracted provider through E&I
              Cooperative Services. SSB is backed by Austin-based private equity
              firm Strattam Capital, and has offices in Denver, Nashville, and
              the Washington DC area.
            </p>
            <a
              href="https://www.ssbinfo.com"
              class="homelink button is-primary"
              target="_blank"
              >Learn More</a
            >
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <img class="homeff" src="../assets/ll.png" alt="" />
          </div>
          <div class="column" style="text-align: left;">
            <p class="date homedate">
              About Legend Labs
            </p>
            <p>
              Legend Labs provides brand and communications consulting services
              to help organizations grow and protect their brands in the digital
              age. With extensive experience working with higher education
              institutions and professional sports organizations and athletes,
              Legend Labs has developed a comprehensive understanding of
              effective fan engagement as well as the brand and reputation
              challenges faced by universities, leagues, conferences, and teams.
              Legend Labs has offices in Austin and New York.
            </p>
            <a
              href="https://www.legendlabs.com"
              class="homelink button is-primary"
              target="_blank"
              >Learn More</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      msg: "foo",
    };
  },
  mounted() {
    document.title = "FanLab - SSB & Legend Labs Fan Engagment";
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["setPath", "/"]);
    _hsq.push(["trackPageView"]);
    document.body.classList.add("home");
  },
  destroyed() {
    document.body.classList.remove("home");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p.test {
  margin-top: 35px !important;
  max-width: 931px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.7;
}

.home .sublogo {
  margin: 0 auto;
  position: absolute;
  top: 19px;
  left: 50%;
  padding: 0 30px;
  background: #1e1e20;
  transform: translateX(-50%);
}
img.ssblogo {
  margin-top: 150px;
  max-width: 330px;
}
.homebg {
  background-image: url("../assets/homebg.png");
}
.homebg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.homeContent {
  z-index: 2;
  position: relative;
}
.sublogo {
  background: transparent;
}
p.date.homedate {
  margin: 60px auto 14px auto;
  font-size: 20px;
  font-family: "EucBld";
}
.lead h1 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-family: "EucBld";
  margin: 40px auto;
  font-size: 20px;
  letter-spacing: 1px;
}
.brought h2 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-family: "EucBld";
  margin: 60px auto 10px;
  font-size: 16px;
  letter-spacing: 0px;
}
img.logo3 {
  max-width: 420px;
}
</style>
