import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import FanFactors from "./components/FanFactors.vue";
import Home from "./components/Home.vue";
import Assessment from "./components/Assessment.vue";
import Webinar from "./components/Webinar.vue";
import Contact from "./components/Contact.vue";
import html2canvas from "vue-html2canvas";
import "./../node_modules/bulma/css/bulma.css";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(html2canvas);
// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/assessment",
    name: "Assessment",
    component: Assessment,
  },
  {
    path: "/fan-factors",
    name: "FanFactors",
    component: FanFactors,
  },
  {
    path: "/webinar",
    name: "Webinar",
    component: Webinar,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes, // short for `routes: routes`
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
