<template>
  <div id="app">
    <a href="/"> <img class="sublogo" src="./assets/sublogo1.png" alt=""/></a>
    <div class="menu" :class="{ openMenu: openMenu }">
      <div class="menu-wrap">
        <div class="menu-logo">
          <img src="./assets/logo.png" alt="" />
        </div>
        <div class="menu-links" @click="openMenu = false">
          <router-link :to="{ name: 'Home' }">Home</router-link>
          <router-link :to="{ name: 'Assessment' }">Assessment</router-link>
          <router-link :to="{ name: 'FanFactors' }">Fan Factors</router-link>
          <router-link :to="{ name: 'Webinar' }">Webinar</router-link>
          <router-link :to="{ name: 'Contact' }">Contact</router-link>
        </div>
      </div>
    </div>
    <div class="header">
      <div class="trigger" @click="showMenu()" style="z-index: 3;">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <router-view></router-view>
    <script
      type="application/javascript"
      src="//js.hsforms.net/forms/v2.js"
    ></script>
    <script
      type="application/javascript"
      id="hs-script-loader"
      async
      defer
      src="//js.hs-scripts.com/6994075.js"
    ></script>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return { openMenu: false };
  },
  mounted() {
    document.title = "FanLab";
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-5MBNZVZ");
  },
  methods: {
    showMenu() {
      this.openMenu = this.openMenu ? false : true;
    },
  },
};
</script>

<style>
.content .blue {
  color: #01c2f7 !important;
}
@font-face {
  font-family: "EucReg";
  src: url("./assets/fonts/EuclidFlex-Regular-WebS.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/EuclidFlex-Regular-WebS.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/EuclidFlex-Regular-WebS.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("./assets/fonts/EuclidFlex-Regular-WebS.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/EuclidFlex-Regular-WebS.svg")
      format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "EucBld";
  src: url("./assets/fonts/EuclidFlex-Bold-WebS.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/EuclidFlex-Bold-WebS.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/EuclidFlex-Bold-WebS.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("./assets/fonts/EuclidFlex-Bold-WebS.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/EuclidFlex-Bold-WebS.svg")
      format("svg"); /* Legacy iOS */
}
html {
  background-color: #1e1e1f !important;
  height: 100%;
}
body {
  color: #fff;
  background-color: #1e1e1f;

  height: 100%;
}
.content li {
  display: list-item;
}
#app {
  font-family: "EucReg", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #fff;
  background-color: #1e1e1f;
  margin-top: 0;
  border: 1px solid #444;
  margin: 50px;
  padding: 50px;
}
.home #app {
  text-align: center;
}
.homebg {
  background-image: url("./assets/homebg.png");
}
.homebg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.assessment #app {
  padding: 50px;
}
.hero h1 {
  font-size: 61px;
  font-family: "EucBld";
  text-align: left;
  line-height: 1.2;
  margin-bottom: 30px;
}
.hero img {
  max-width: 270px;
  max-width: 350px;
  margin-top: -60px;
}
.ready img {
  max-width: 310px;
  margin-top: -140px;
  margin-left: 100px;
}
img.logo2 {
  max-width: 300px;
  margin: 0 auto;
  margin-top: 0;
}
.herotext img {
  max-width: 140px;
  padding-top: 20px;
  margin-top: 0;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 60px;
}
.sublogo {
  margin: 0 auto;
  position: absolute;
  top: 19px;
  left: 50%;
  padding: 0 30px;
  background: #1e1e20;
  transform: translateX(-50%);
}
.hero.subhero {
  margin-top: 62px;
}
.trigger span {
  height: 2px;
  width: 30px;
  background: #fff;
  margin-bottom: 6px;
  display: block;
  transition: 0.2s all ease;
}
.trigger {
  position: fixed;
  top: 78px;
  right: 74px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  z-index: 99999 !important;
}
.trigger:hover span {
  transform: translate(0px, -2px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}
.content h1,
.content h2,
.content h3,
.content h4,
.content h5 {
  color: #fff !important;
}
strong,
b {
  font-family: "EucBld", Helvetica, Arial, sans-serif;
}
.hero .column {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero .column.herotext {
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
}
.menu {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 400px;
  background: #fff;
  transform: translateX(-410px);
  transition: 0.3s all ease;
  z-index: 999;
}
.openMenu {
  transform: translateX(0);
}
.menu-wrap {
  padding: 70px 60px;
  /* border-right: 1px solid #c0e5ff; */
  background: #161616;
  box-shadow: 0 0px 17px 6px #00000038;
  border-right: 2px solid #44444440;
}
.menu-links {
  display: flex;
  flex-direction: column;
}
.menu-links a {
  padding: 20px;
}
.control.inlines input {
  z-index: -9999;
  position: absolute;
}
.control.inlines {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
}
.menu-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.menu-links a:hover {
  color: #01c2f7 !important;
}
.content .qs {
  margin-bottom: 9px;
  border-bottom: 1px solid #232323;
  padding-bottom: 6px;
}
.content .qs h1 {
  font-size: 18px;
  margin-bottom: 0.5em;
  width: 70%;
  margin: 0;
  padding: 0;
  text-align: left;
  font-family: "EucReg" !important;
  line-height: 1.4;
  font-weight: normal;
}
.content div.qs {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.intro.content {
  border-top: 1px solid #333;
  border-bottom: 1px dashed #515152;
  padding-bottom: 42px;
  margin-bottom: 40px;
  margin-top: 20px;
  padding-top: 50px;
}
.formGroup input {
  background: #1e1e1f;
  border: 2px solid #ececec;
  color: #fff;
  /* max-width: 430px; */
  border-radius: 0px;
  padding: 17px 22px;
  height: 59px;
  border-radius: 3px;
  font-size: 18px;
}
.formGroup {
  margin-top: 51px;
  max-width: 920px;
  margin: 0 auto;
  margin: 50px auto 0 auto;
}
.content div.qs:last-child {
  border-color: transparent;
}
hr {
  background-color: #303030;
  border: none;
  display: block;
  height: 2px;
  margin: 1.5rem 0;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b7b7b7 !important;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b7b7b7 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b7b7b7 !important;
}

.button.is-primary {
  background-color: #34ace2;
  border-color: transparent;
  color: #fff;
  font-family: "EucBld", Helvetica, Arial, sans-serif;
  font-size: 20px;
  margin-top: 19px;
}
.button.is-primary {
  background-color: #34ace2 !important;
  border-color: transparent;
  color: #fff;
  padding: 32px 40px;
  font-size: 29px;
  font-family: "EucReg";
}
.button.is-primary:hover,
.button.is-primary.is-hovered {
  background-color: #47baed !important;
  border-color: transparent;
  color: #fff;
}
.menu-links a {
  color: #e3e3e3;
  font-size: 18px;
  letter-spacing: 1px;
}

.ansItem {
  margin-bottom: 20px;
}
div#form1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
div#form1 h1 {
  font-size: 35px;
  font-family: "EucBld";
  text-align: center;
  line-height: 1.2;
  margin-bottom: 8px;
}
#form1 {
  background: #1e1e1f;
}
#form1 .input,
#form1 .textarea,
#form1 .select select {
  background-color: transparent;
  border-color: #dbdbdb;
  border-radius: 4px;
  color: #fff !important;
  border: none !important;
  padding: 0;
}
#form1 label {
  color: #fff;
}
#form1 input[type="tel"],
#form1 input[type="email"],
#form1 input[type="text"],
#form1 .input {
  background: #141414;
  box-shadow: none !important;
  border: none;
  padding: 9px 10px;
  width: 100%;
  color: #fff !important;
  display: flex;
}

#form1 fieldset {
  margin-bottom: 16px;
}
ul.no-list.hs-error-msgs.inputs-list {
  font-size: 13px;
  margin: 10px 3px 20px;
}
input.hs-button.primary.large {
  background-color: #34ace2 !important;
  border-color: transparent;
  color: #fff;
  margin-top: 20px;
  padding: 16px 40px;
  font-size: 29px;
  font-family: "EucReg";
}
ul.inputs-list.multi-container {
  list-style: none;

  margin-top: 1em;
}

#form1 .hs-fieldtype-checkbox li input {
  display: inline;
}
#form1 .hs-fieldtype-checkbox .input span {
  margin-left: 10px;
}
#form1 .hs-fieldtype-checkbox .input {
  display: inline;
  background: transparent;
}
.sublogo {
  z-index: 999;
}
.home .sublogo {
  z-index: 0;
}
.capcap {
  padding: 30px;
}
.homeread {
  margin: 60px 0 20px 120px;
  max-width: 295px;
}
.homeff {
  margin: 40px 0 20px 50px;
  max-width: 400px;
}
.ansq {
  font-family: "EucBld";
  margin-bottom: 8px;
  color: #34ace2;
  margin-top: 20px;
}
.ready.webinar img {
  margin: 4px;
  /* max-width: 400px; */
  width: 550px;
  max-width: unset;
  margin-top: -60px;
}
.fanimage {
  max-width: 590px !important;
  margin: 0;
  margin-top: -80px;
}
.resAns {
  width: 70%;
  margin-left: 20px;
  margin-bottom: 60px;
}
#form1 strong {
  color: #fff;
}
.home .button.is-primary {
  padding: 13px 30px;
  height: auto;
  margin-top: 40px;
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 1px;
  /* background: rgb(8 7 7 / 57%) !important;
  border: 2px solid #01c2f7; */
  border-radius: 3px;
}
.home .button.is-primary:hover {
  /* color: #01c2f7;
  border: 2px solid #12c9fc;
  background: rgb(8 7 7 / 57%) !important; */
}
@media (max-width: 768px) {
  .assessment #app {
    padding: 20px;
  }
  #app {
    margin: 25px;
    padding: 25px;
  }
  .capcap {
    padding: 10px;
  }

  .fanimage {
    max-width: 330px;
    margin: 0;
    margin-top: -80px;
  }
  .homeread {
    margin: 50px auto 0;
    max-width: 230px;
  }
  .homeff {
    margin: 30px auto 0;
    max-width: 330px;
  }
  p.date.homedate {
    margin: 10px auto 14px auto !important;
  }
  .content .qs {
    flex-wrap: wrap;
  }
  .content .qs h1 {
    width: 100%;
  }
  .content .qs .control.inlines {
    width: 100%;
    justify-content: flex-start;
    margin: 15px auto;
  }
  .content .qs span {
    /* background: #00aeef; */
    color: #34ace2;
    padding: 20px;
    font-size: 20px;
    padding: 3px;
    border-radius: 3px;
    font-family: "EucBld";
    margin-bottom: 0;
    float: none;
    margin-right: 17px;
    width: 23px;
    height: 21px;
    display: block;
    font-size: 17px;
    float: left;
  }
  .ready img {
    max-width: 220px;
    margin-top: -50px;
    margin-left: -20px;
  }
  .clickshare {
    display: none !important;
  }
  .resWrap {
    display: flex;
    text-align: left;
    flex-direction: column;
  }
  .resAns {
    width: 93%;
    margin-left: 6px;
    margin-bottom: 60px;
  }
  .hero h1 {
    font-size: 28px;
    font-family: "EucBld";
    text-align: left;
    line-height: 1.2;
    margin-bottom: 14px;
  }
  .ready.webinar img {
    margin: 4px;
    /* max-width: 400px; */
    width: 334px;
    max-width: unset;
    margin-top: 0;
  }
  img.logo3 {
    max-width: 420px;
    width: 100%;
  }
  .home .trigger {
    position: fixed;
    top: 28px;
    right: 14px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    z-index: 99999 !important;
  }
  .trigger {
    top: 85px;
    right: 44px;
  }
  .home .button.is-primary {
    padding: 9px 11px;
    height: auto;
    margin-top: 40px;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    border-radius: 3px;
  }
  .homeff {
    margin: 30px auto 0;
    max-width: 281px;
  }
}
</style>
