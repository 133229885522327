<template>
  <div class="hello">
    <script
      type="application/javascript"
      async
      src="//js.hsforms.net/forms/v2.js"
    ></script>
    <div class="hero subhero">
      <div class="columns text-center">
        <div
          class="column herotext"
          style="display: flex;flex-direction:column;align-items:center;justify-content:center;"
        >
          <h1>Contact</h1>
          <p class="hi" style="margin-bottom: 0 !important;">
            Fill out the form to get in touch!
          </p>
          <div id="form1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      msg: "bar",
    };
  },
  mounted() {
    document.title = "FanLab - Contact";
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["setPath", "/contact"]);
    _hsq.push(["trackPageView"]);
    document.body.classList.remove("Contact");
    let d = document.getElementsByClassName("hbspt-form")[0];
    if (d) {
      d.remove();
    }
    document.body.classList.add("Contact");
    setTimeout(() => {
      // eslint-disable-next-line
      hbspt.forms.create({
        portalId: "6994075",
        formId: "8b45ae48-cd5d-4941-be9a-66186f06948f",
        target: "#form1",
      });
    }, 1000);
  },
  destroyed() {
    document.body.classList.remove("Contact");
    let d = document.getElementsByClassName("hbspt-form")[0];
    let d1 = document.getElementsByClassName("hbspt-form")[1];
    if (d || d1) {
      d.remove();
      d1.remove();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.Contact div#form1 h1 {
  display: none;
}
</style>
